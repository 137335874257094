@font-face {
    font-family: Quicksand-Bold;
    src: url(../fonts/Quicksand-Bold.ttf) format('truetype');
}

@font-face {
    font-family: Quicksand-Light;
    src: url(../fonts/Quicksand-Light.ttf) format('truetype');
}

@font-face {
    font-family: Quicksand-Medium;
    src: url(../fonts/Quicksand-Medium.ttf) format('truetype');
}

@font-face {
    font-family: Quicksand-Regular;
    src: url(../fonts/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
    font-family: Quicksand-SemiBold;
    src: url(../fonts/Quicksand-SemiBold.ttf) format('truetype');
}

/* html, body {
    min-width: 470px !important;
    object-fit: contain;
    position: relative;
    width:100%;
    overflow-x: hidden;
} */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Quicksand-Regular;
}

p,
span,
div {
    font-family: Quicksand-Regular;
}

label,
strong {
    font-family: Quicksand-Regular;
    font-size: 16px;
}

.card-area {
    font-family: Quicksand-Regular;
    font-size: 16px;
}


/* Login Form */

.login {
    background-color: #F7F9FA;
    padding-top: 10%;
    padding-bottom: 10%
}

.loginForm {
    width: 100%;
    max-width: 300px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;
}

.loginImg {
    width: 150px
}

.paddingStyle {
    padding-top: 30px
}

.loginbtn {
    width: 100%;
    background-color: #2C88D9 !important;
    border: 0px !important;
}

.form-control {
    font-family: Quicksand-Regular;
    font-size: 14px;
}


/* .forgetlink{
    float: right;
    text-decoration: underline;
    color: blue !important;
    font-size: 14px !important;
} */


/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

@media screen and (max-width:578px) {
    .displaybreak {
        display: block !important;
    }
    /* .align-adj {
        right: 0px !important;
        margin-right: 0px !important;
        display:flex;
        justify-content: center;
        align-items: center;
    } */
    .nav-tabs {
        margin: auto !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .item {
        display: none;
    }
    .navFontEnd {
        display: none;
    }
    .list-padding {
        /* margin: auto !important; */
        justify-content: center !important;
        align-items: center !important;
    }
    .nav-icon {
        font-size: 20px;
        text-align: center !important;
        margin-left: 10px;
    }
    .link-ac {
        color: #0C4F7D !important;
        cursor: default;
        border-bottom: 0px solid !important;
        border-left: 0px solid #000 !important;
        border-color: #0C4F7D;
    }
    .link-ac .i {
        color: #0C4F7D !important;
        cursor: default;
    }
    .item-arrow {
        display: none;
    }
    .profile {
        display: none;
    }
    .profileMobile {
        display: block !important;
        background-color: #fff;
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.19);
    }
    .sub-menu {
        margin-left: 0px !important;
    }
    .wrapper .sidebar {
        background: #DFE6ED;
        position: fixed;
        top: 0;
        left: 0;
        width: 70px !important;
        height: 100%;
        padding-left: 20px 0;
        padding-right: 20px 0;
        transition: all 0.5s ease;
    }
    .wrapper .sidebar .profileMobile {
        margin-bottom: 30px;
        text-align: center;
    }
    .wrapper .sidebar .profileMobile img {
        padding-top: 10px !important;
        display: block;
        width: 30px;
        margin: 0 auto;
        padding-bottom: 10px;
    }
    .wrapper .sidebar ul li a {
        display: block;
        padding: 10px 10px 10px 10px!important;
        border-bottom: none;
        color: rgb(241, 237, 237);
        font-size: 16px;
        position: relative;
        color: #4B5C6B;
        margin: auto !important;
        justify-content: center !important;
        align-items: center !important;
    }
    .wrapper .sidebar ul li a:hover,
    .wrapper .sidebar ul li a.active {
        color: #ccc !important;
        border: none;
        background: #555;
        border-right: 2px solid rgb(146, 146, 146);
    }
    .wrapper .sidebar ul li a:hover,
    .wrapper .sidebar ul li a.active {
        color: #0c7db1;
        border: none;
    }
    .wrapper .sidebar ul li a:hover:before,
    .wrapper .sidebar ul li a.active:before {
        display: block;
        border: none;
    }
    .wrapper .section {
        width: calc(100% - 70px) !important;
        margin-left: 70px !important;
        transition: all 0.5s ease;
    }
    .wrapper .section .top_navbar {
        background: #fff;
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 30px;
    }
    .displaybreak {
        display: none;
    }
    .wrapper .section .top_navbar .hamburger a {
        font-size: 28px;
        color: #f4fbff;
    }
    .wrapper .section .top_navbar .hamburger a:hover {
        color: #235;
    }
    body.active .wrapper .sidebar {
        left: -70px !important;
    }
    body.active .wrapper .section {
        margin-left: 0 !important;
        width: 100% !important;
    }
    .fa-bars {
        color: #788896 !important;
    }
    ul {
        padding-left: 1px !important;
    }
    a,
    i {
        text-decoration: none !important;
        color: #333 !important;
    }
    .create-form label {
        color: black !important;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px !important;
        align-items: center;
        align-content: center;
    }
    .main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #dee4ec;
        color: rgb(255, 244, 244);
        flex-direction: column;
    }
    table {
        margin: 20px !important;
    }
    .form .row {
        margin-bottom: 4px !important;
    }
    .top_navbar {
        width: 100%;
        box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.19);
        position: fixed;
        top: 0;
        z-index: 55;
    }
    /* body.active .wrapper .sidebar{
    left: -70px !important;
  }

  .sidebar {
    padding-top: 70px;
    text-align: center !important;
    margin: auto !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .wrapper .sidebar{
    background: #DFE6ED;
    position: fixed;
    top: 0;
    left: 0;
    max-width: 70px !important;
    height: 100%;
    padding-left: 20px 0;
      padding-right: 20px 0;
    transition: all 0.5s ease;
  }

  .wrapper .section{
    width: calc(100% - 70px) !important;
    margin-left: 0px !important;
    transition: all 0.5s ease;
  }  */
}

* {
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body {
    background: #f4f4f4 !important;
}

.list-padding {
    margin-bottom: 8px;
    cursor: default;
}

.nav-div {
    padding: 5px;
}

.nav-div:hover {
    background-color: #555;
    border: none;
    color: #ccc !important;
}


.wrapper {
    width:100%;
    position: relative;
    object-fit: contain;
    box-sizing: border-box;
    overflow-x: hidden;
}

.wrapper .sidebar {
    background: #DFE6ED;
    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding-left: 20px 0;
    padding-right: 20px 0;
    transition: all 0.5s ease;
}

.wrapper .sidebar .profile {
    margin-bottom: 30px;
    text-align: center;
}

.wrapper .sidebar .profile img {
    padding-top: 5px !important;
    display: block;
    width: 100px;
    margin: 0 auto;
    padding-bottom: 4px;
}

.wrapper .sidebar ul li a {
    display: block;
    padding: 5px 10px;
    border-bottom: none;
    color: rgb(241, 237, 237);
    font-size: 16px;
    position: relative;
    color: #4B5C6B;
}

.wrapper .sidebar ul li a .icon {
    color: #dee4ec;
    width: 30px;
    display: inline-block;
    border: none;
}

.icon {
    font-size: 24px;
    display: block;
    color: #000000;
}

.wrapper .sidebar ul li a:hover,
.wrapper .sidebar ul li a.active {
    color: #ccc !important;
    border: none;
    background: #555;
    border-right: 2px solid rgb(146, 146, 146);
}

.wrapper .sidebar ul li a:hover,
.wrapper .sidebar ul li a.active {
    color: #0c7db1;
    border: none;
}

.wrapper .sidebar ul li a:hover:before,
.wrapper .sidebar ul li a.active:before {
    display: block;
    border: none;
}

.wrapper .section {
    width: calc(100% - 225px);
    margin-left: 225px;
    object-fit: contain;
    transition: all 0.5s ease;
}

.wrapper .section .top_navbar {
    background: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 30px;
}

.displaybreak {
    display: none;
}

.wrapper .section .top_navbar .hamburger a {
    font-size: 28px;
    color: #f4fbff;
}

.wrapper .section .top_navbar .hamburger a:hover {
    color: #235;
}

body.active .wrapper .sidebar {
    left: -225px;
}

body.active .wrapper .section {
    margin-left: 0;
    width: 100%;
}

.fa-bars {
    color: #788896 !important;
}

ul {
    padding-left: 1px !important;
}

a,
i {
    text-decoration: none !important;
    color: #333 !important;
}

.create-form label {
    color: black !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px !important;
    align-items: center;
    align-content: center;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #dee4ec;
    color: rgb(255, 244, 244);
    flex-direction: column;
}

table {
    margin: 20px !important;
}

.form .row {
    margin-bottom: 4px !important;
}

.profile {
    background-color: #fff;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.19);
}

.profileMobile {
    display: none;
}

.top_navbar {
    width: 100%;
    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.19);
    position: fixed;
    top: 0;
    z-index: 55;
}


/* head line view */

.headline {
    width: 100%;
    display: block;
}


/* Style The Dropdown Button */

.dropbtn {
    background-color: #ffffff;
    color: #222;
    padding: 5px;
    font-size: 14px;
    border: none;
    cursor: pointer;
}


/* The container <div> - needed to position the dropdown content */

.dropdown {
    position: relative;
    display: inline-block;
}


/* Dropdown Content (Hidden by Default) */

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 110px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.sub-menu {
    margin-left: 20px;
    font-size: 14px !important;
    display: none;
}

.show-nav {
    display: block;
}


/* Links inside the dropdown */

.dropdown-content a {
    color: #4B5C6B;
    padding: 5px 10px;
    text-decoration: none;
    display: block;
}


/* Change color of dropdown links on hover */

.dropdown-content a:hover {
    background-color: #c4c4c4
}


/* Show the dropdown menu on hover */

.dropdown:hover .dropdown-content {
    display: block;
}


/* Change the background color of the dropdown button when the dropdown content is shown */

.dropdown:hover .dropbtn {
    background-color: #ffffff;
}

.dropdown-content {
    right: 0;
}

.profileIcon {
    font-size: 20px !important;
    color: #9EADBA !important;
    margin-top: 0px;
}

.iconStyle {
    font-size: 16px !important;
    color: #586b7c !important;
    padding: 5px;
}

.right-group {
    /* float: right !important; */
    position: fixed !important;
    margin-left: auto;
    margin-right: 10px;
    right: 10px;
}

.icond {
    font-size: 25px;
    margin-left: 5px;
    text-align: left;
}

/* .item {
    padding-left: 30px;
} */

.item-arrow {
    margin-left: 40px;
}

.align-adj {
    margin-top: 80px !important;
}

.align-form {
    display:flex;
    justify-content: center;
    align-items: center;
}
.card-form {
    background-color: #ffffff;
    width: 80%;
    margin-top: 80px !important;
    padding: 20px !important;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.loading-space {
    width: 80%;
    height: auto;
    justify-content: center;
    display: flex;
    align-items: center;
}

.cont {
    position: sticky;
    left: 0px;
}

.alignLeft {
    float: left !important;
    left: 0px;
    /* position: relative; */
    padding: 20px;
    color: #5f5f5f !important;
    margin-right: auto;
}

.alignRight {
    float: right !important;
    right: 0px;
    margin-right: auto;
    padding: 20px;
    color: #5f5f5f !important;
    position: absolute;
    display: block;
    margin-left: auto;
}

.btn-st {
    border: none;
    background-color: transparent;
}

.btn-view {
    border: none;
    background-color: transparent;
    color: rgb(0, 70, 211);
    text-decoration: underline;
}

.align-ico {
    align-items: baseline;
    align-content: center;
    vertical-align: baseline;
}

.btn-create {
    color: #fff !important;
    margin-top: 5px;
    font-size: 14px;
    padding: 8px 15px 8px 15px;
    margin-left: auto;
    margin-right: auto;
    background-color: #0C4F7D !important;
    border: 0px solid;
}

.table-striped {
    /* width: 70% !important; */
    margin-left: auto !important;
    margin-right: auto !important;
    margin-left: 20px;
}

.close {
    display: flex;
    margin-left: auto;
    float: left;
}

.labelForm {
    /* font-weight:bold; */
    margin-right: auto;
    width: 100%;
    padding-top: 18px;
}

.inputForm {
    padding: auto;
    border: none;
    border: 1px rgb(184, 229, 255) solid;
    width: 100%;
    padding: 10px;
    font-size: 11px;
}


/* .create-form{
      max-width: 300px;
  } */

.modalClass {
    max-width: 500px;
}

.deleteModal {
    padding: auto;
    background-color: rgba(221, 0, 0, 0.4) !important;
    color: #fff;
}


/* nav dropdown */


/* 
li {
  display: block;
  transition-duration: 0.5s;
 }
 
 li:hover {
   cursor: pointer;
 }

ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
}

ul li:hover > ul,
ul li ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

ul li ul li {
   clear: both; 
  width: 100%;
} */


/* partner create button */

.btn-partner {
    width: 100%;
    max-width: 200px;
    display: block;
    color: #fff !important;
    font-size: 14px;
    padding: 8px 15px 8px 15px;
    right: 0em;
    position: relative;
    margin-bottom: 20px;
    background-color: #0C4F7D !important;
    border: 0px solid;
}


/* Card Design */

.card-img {
    width: 150px !important;
    display: inline-block !important;
    /* width:100%; */
    /* border-bottom: 1px #788896 solid; */
}

.service-style {
    height: 10px;
    display: block;
}

.card-footer {
    background-color: #DFE6ED !important;
    align-items: baseline;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    /* position: absolute !important;
  right: 0px !important; */
}

.card-del {
    font-size: 30px !important;
    /* float:right; */
    color: red !important;
    padding-left: auto;
    right: 0em;
    padding-left: 5px;
}

.card-update {
    font-size: 30px !important;
    float: right;
    color: #0C4F7D !important;
    padding-left: 5px;
}

.service {
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px 0 rgba(64, 0, 80, 0.19) !important;
}

.card-area {
    width: 100%;
    border: 0px;
    text-align: justify;
    /* padding-bottom: 0%; */
    overflow: hidden;
}

.card-input {
    clear: all;
    width: 100%;
    border: 0px;
    text-align: justify;
}


/* upload Image Design */

.fileUpload {
    background-color: #0C4F7D !important;
    border: 0px solid;
    color: #fff;
    font-size: 18px;
    margin: 0px;
    /*20px/16px 0*/
    overflow: hidden;
    position: relative;
    right: 0px;
    float: right;
    text-align: center;
    width: 120px;
    cursor: pointer;
    clear: both;
    display: block;
    font-size: 14px;
    padding: 8px 15px 8px 15px;
}

.upload {
    margin-top: 20px;
}

.fileUpload:hover,
.fileUpload:active,
.fileUpload:focus {
    background: #00a2a4;
    cursor: pointer;
}

.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 148px;
    height: 46px;
    cursor: pointer;
}

input[type="file"] {
    /* position: fixed; */
    right: 100%;
    bottom: 100%;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.clear-float {
    clear: both;
    padding-bottom: 10px;
}


/* image rounded for logo */

.circular-img {
    display: inline-block;
    border-radius: 20%;
    background: transparent;
    height: auto;
    width: 100%;
    border: 2px solid rgb(192, 224, 255);
}

.circular-img img {
    display: inline-block;
    margin-top: 5px;
    margin-left: 10px
}


/* for mobile view */

@media only screen and (max-width: 640px) {
    /* .circular-image{
        border-radius:50%;
} */
}

.logo {
    border: 0px solid !important;
    border-radius: 30px;
    box-shadow: 1px 4px 2px -1px rgba(0, 0, 0, 0.19);
    margin-bottom: 20px;
    /* border-top-left-radius: 45%;
  border-top-right-radius: 45%; */
}

.bg-img-card {
    border-bottom-right-radius: 10%;
    border-bottom-left-radius: 10%;
    box-shadow: 1px 6px 3px 1px rgba(0, 0, 0, 0.19);
    margin-bottom: 20px;
}

.btn-bg {
    margin-top: 30px !important;
    width: 100%;
    max-width: 250px;
    display: block;
    background-color: #0C4F7D;
    border: 0px solid;
    color: #fff !important;
    right: 0em;
    position: relative;
    margin-bottom: 10px;
}

.img-width {
    width: 100px;
    height: 100px;
}

.text-limit {
    width: 250px;
}

.tb-pad {
    padding: 20px;
}


/* circle active style */

.ring-container {
    position: relative;
}

.circle {
    width: 15px;
    height: 15px;
    background-color: #62bd19;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    left: 14px;
}

.changebtn {
    background-color: #0C4F7D;
    color: #fff !important;
    border: 0px solid;
    font-size: 14px;
    padding: 8px 15px 8px 15px;
    border-radius: 5px;
    float: right;
}

.del-padding {
    position: relative;
    font-size: 25px !important;
    padding-right: 10px;
    color: red !important;
}

.mak-cen {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* .nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus  */

.list-padding:hover,
.list-padding:active,
.list-padding:focus {
    outline: 0;
}

.link-ac {
    color: #0C4F7D !important;
    cursor: default;
    border-left: 5px solid;
    border-color: #0C4F7D;
}

.link-ac .i {
    color: #0C4F7D !important;
    cursor: default;
}


/* Loading Design */

.loading {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: transparent !important;
    backface-visibility: hidden;
}

.header-st {
    position: relative;
    display: inline-block;
}

.rdt_TableCell div {
    display: block !important;
}

.sc-hKgJUU {
    /* background-color: #0C4F7D; */
    display: flex !important;
}

.datatable_img_div .inside_img_div {
    margin: 2px;
    display: inline !important;
}

.datatable_img_div .inside_img_div img {
    margin-top: 3px;
    margin-bottom: 3px;
}


/* TOGGLE SWITCH STYLE */

.tog-switch {
    font-size: 20px;
}

.modal-style .modal-body {
    max-width: 850px !important;
}


/* text Editor */

.ql-editor {
    min-height: 12em;
}

.main_img_detail_view {
    width: 100%;
}

.img_detail_view img {
    display: inline;
    height: 150px;
    width: 150px;
}

.img_detail_view {
    display: flex !important;
}

.img_div {
    margin: 2px;
    display: inline !important;
}

.img_div img {
    margin-top: 3px;
    margin-bottom: 3px;
}

.img-update-btn {
    color: #ffffff;
    font-family: Quicksand-SemiBold;
    font-size: 14px;
}

.warning_div {
    margin: auto;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.press_img_div {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datatab-link {
    color: #0c7db1 !important;
    cursor: pointer;
}


.hidden{
    display: none !important;
  }

  .btn-adjust-content {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .img-position {
    position: relative;
  }

  .img-position-child {
    position: absolute;
    bottom: 0;
    margin-left: 5px;
    color: rgb(252, 96, 96);
  }